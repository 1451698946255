import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Fade } from "react-reveal"

function Opener(props) {
  console.log(props)
  const [show, setShow] = useState(true)
  const [no, setNo] = useState(false)
  const [loading, setLoading] = useState(true)

  const Bg = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  useEffect(() => {
    const hideOpener = localStorage.getItem("hideOpener")
    if (false) {
      setNo(true)
      setShow(false)
      return
    }
    setLoading(false)
    localStorage.setItem("hideOpener", true)
    document.getElementsByTagName("html")[0].style.overflow = "hidden"
    setTimeout(() => {
      document.getElementsByTagName("html")[0].style.overflow = "visible"
      setShow(false)
      setTimeout(() => {
        setNo(true)
      }, 500)
    }, 8000)
  }, [])

  return (
    <div
      style={{
        display: no ? "none" : "block",
        transition: "all 1s",
        opacity: show ? 1 : 0,
        position: "fixed",
        zIndex: 9999,
        height: "100vh",
        width: "100vw",
        background: "white",
      }}
    >
      {!loading && (
        <Bg>
          <Img
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            fluid={props.bg.childImageSharp.fluid}
          />
          <img
            style={{ maxWidth: "500px", width: "100%", zIndex: 1 }}
            src="/cucmber.gif"
          />
        </Bg>
      )}
    </div>
  )
}

export default Opener
