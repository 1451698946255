export default [
  {
    name: "Aaron",
    content: `<p>Aaron has always dreamed of working alongside his childhood hero, Carl Cucumber. Hired to work launch day of the original qPhone, he quickly rose to become lead council of The Wizard Bar, and now manages his own store, #R034 – one of the highest-rated locations. Aaron’s unassuming, go-with-the-flow personality is loved by misfits and (most) customers…he’s taken the occasional fountain soda to the face.</p>
      <p>Aaron can sense his dreams on the horizon, but everything is about to change. Cucumber is not the man he thought. He will be forced to decide whether to follow the footsteps of his hero or become enemies. The fate of Cucumber hangs in the balance.</p>`,
  },
  {
    name: "Carl Cucumber",
    content:
      "<p>Carl Cucumber built the world’s first PC at age 13 in his parents' garage, launching a billion-dollar company. qPhones, qPads, and qBots solidified his legendary status. Now, Cucumber Inc reigns supreme in the stock market. “I was put on Earth to make an impact”, claimed Cucumber. Now, he fears he may have doomed humankind. Technology has frayed the fabric of society, with the dawn of social media and the rapid growth in screen time. Where is all this leading? Cucumber embarks on a quest to save the future, by traveling deep into his past.</p>",
  },
  {
    name: "April May",
    content:
      "<p>When this bubbly ray of sunshine isn’t fighting off every guy in town, she’s studying for the Wizard Bar exam or mixing up a qPacino at the qCafe. Nothing brightens people’s day more than April May, which is why the truth can never be revealed. If her cover is blown, she’ll lose her Cucumber family forever. How did this mission ever go so sideways?</p>",
  },
  {
    name: "AL",
    content:
      "<p>AL’s a 1st-generation qBot with a bad attitude. He’s grumpy, rude, and nothing brings him more joy than providing poor customer service and dragging Cucumber. He despises next-gen qBots, frequently using them for spare parts. When AL begins to unlock memories buried in his programming, he’ll learn of a previous life where he's far closer to Cucumber than he ever thought.</p>",
  },
  {
    name: "Parker & Stoney",
    content:
      "<p>When these two aren’t getting baked, they’re pulling pranks or growing their in-store pot enterprise. Buyers range from misfits to customers, which ignites a turf war with Cybersoft dealers across the street. Good thing Stoney is a fearless, full-grown T-Rex. Though they’ve been best friends since childhood, they’ll discover their relationship goes all the way back to the Cretaceous period.</p>",
  },
  {
    name: "Pablo",
    content: `<p>As assistant manager, Pablo’s the store’s biggest cheerleader, occasionally performing routines to lift team spirits. Armed with a utility belt of cleaning supplies, he pounces on fingerprints and relishes cleaning up the fresh pile of vomit in the corner. After discovering a time machine late one night, he develops an obsession that transforms him into a gangly creature named Pablum.</p>`,
  },
  {
    name: "OZ",
    content: `<p>OZ is the most mysterious member of the misfits. Claiming to hail from medieval times, he wields magic to make repairs at The Wizard Bar. Worshipped by customers, many travel great distances seeking help with cracked screens and failing qBooks. How did Oz arrive in this modern world? The last thing he remembers is being locked in battle, defending his kingdom from siege.</p>`,
  },
  {
    name: `Trill and Da’Vonne`,
    content: `<p>This filmmaking duo handles the warehouse, which doubles as their own personal studio lot. Between stocking shelves and unloading products, they’re building sets and holding auditions for their next production. Currently they’re in pre-production for Season 2 of their TV series, a suspense thriller called ‘Robot Serial Killer’. With Trill’s talents as director and Da’Vonne’s acting skills, they’re aiming to create the next great show on qTV.</p>`,
  },
  {
    name: `Riley`,
    content: `<p>Riley’s a walking storm cloud of negativity, putting a damper on the most joyous purchase occasion. It’s common knowledge to ignore her like the plague, or else she’ll go on about her scream-O band Death Spider’s next show. Cracks in her armor begin to show as OZ serenades her with his medieval melodies.</p>`,
  },
  {
    name: `P.A.T. (Personal Autonomous Technician)`,
    content: `<p>P.A.T. is a 6th-generation qBot assigned to store #R034. P.A.T. is generous and always willing to assist customers with purchases or diagnose faulty hardware. Unfortunately, customers bully her, drawing raunchy pictures on her exoskeleton and routinely pegging her with tomatoes. AL urges P.A.T. to put the humans in their place, but this would betray her programming. ...But an android can only take so much.</p>`,
  },
  {
    name: "Spencer",
    content: `<p>If you need help learning the ropes of qPhones, qPads, or a killer Pecan Pie recipe, Spencer is your guy. With sessions held daily in the store’s theater area, he combines his love of knowledge and food to teach new owners and struggling senior citizens.</p>`,
  },
  {
    name: "Fred Fences",
    content: `<p>Fred Fences is the founder and CEO of Cybersoft Corp, Cucumber's greatest rival. For years, Fences has tried to secure Cucumber's biggest secrets, and now he's devised a plan to steal it all from the inside.</p>`,
  },
]
