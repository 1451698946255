import React, { useState } from "react"
import { useStaticQuery } from "gatsby"
import { Fade } from "react-reveal"
import Img from "gatsby-image"
import styled from "styled-components"
import castInfo from "../cast-info/cast-info"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function Cast() {
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 6,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          arrows: true,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query CastQuery {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/images/cast/" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxHeight: 650, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const [currentCast, setCurrentCast] = useState(0)
  const images = data.allFile.edges.sort((a, b) => {
    return parseFloat(a.node.name) - parseFloat(b.node.name)
  })

  const CastContainer = styled.div`
    position: relative;
    height: 600px;
    .main {
      width: 350px;
      position: absolute !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .small {
      position: absolute !important;
      width: 120px;
      :nth-of-type(1) {
        top: -194px;
        left: 91px;
        z-index: 1;
      }
      :nth-of-type(2) {
        top: -281px;
        left: 234px;
        z-index: 1;
      }
      :nth-of-type(3) {
        top: -236px;
        left: 390px;
        z-index: 1;
      }
      :nth-of-type(4) {
        top: -90px;
        left: 452px;
        z-index: 1;
      }
      :nth-of-type(5) {
        top: 69px;
        left: 408px;
        z-index: 1;
      }
      :nth-of-type(6) {
        left: 260px;
        z-index: 1;
        top: 142px;
      }
      :nth-of-type(7) {
        top: 70px;
        left: 102px;
        z-index: 1;
      }
    }
    .cast-image-container {
      flex-basis: 670px;
      img {
        border-radius: 100%;
      }
    }
    .cast-slider {
      display: none;
    }

    .slick-prev:before,
    .slick-next:before {
      color: black;
    }
    .image-container {
      position: relative;
      z-index: 2;
      img {
        border-radius: 100%;
      }
    }

    .cast-slider img {
      width: 100%;
    }
    .cast-slider .slick-track {
      display: flex;
      align-items: flex-end;
    }
    .cast-slider .slick-track > * {
      margin: 20px;
      position: relative;
    }

    .small:hover .spin::before {
      border-top-color: #70d47e;
      border-right-color: #70d47e;
      border-bottom-color: #70d47e;
      transition: border-top-color 0.15s linear,
        border-right-color 0.15s linear 0.1s,
        border-bottom-color 0.15s linear 0.2s;
    }
    .small:hover .spin::after {
      border-top: 7px solid #70d47e;
      border-left-width: 7px;
      border-right-width: 7px;
      transform: rotate(270deg);
      transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s,
        -webkit-transform 0.4s linear 0s;
    }

    @media (max-width: 1030px) {
      flex-direction: column-reverse !important;
      height: auto;
      > div {
        width: 100%;
      }
      > div:nth-of-type(1) {
        margin-right: 0 !important;
      }
      .cast-slider {
        display: block;
      }
      .cast-image-container {
        display: none;
      }
      .cast-slider-container {
        box-sizing: border-box;
        padding: 0 25px;
      }
    }
  `

  const CircleContainer = styled.div`
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%);
    width: calc(100% + 5px);
    padding-top: calc(100% + 5px);
    button {
      outline: none;
      border: 0;
      position: relative;
      background: none;
      cursor: pointer;
    }

    button::before,
    button::after {
      box-sizing: inherit;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .spin {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .spin::before,
    .spin::after {
      top: 0;
      left: 0;
    }

    .spin::before {
      border: 7px solid transparent;
    }

    .spin.active::before {
      border-top-color: #70d47e;
      border-right-color: #70d47e;
      border-bottom-color: #70d47e;
      transition: border-top-color 0.15s linear,
        border-right-color 0.15s linear 0.1s,
        border-bottom-color 0.15s linear 0.2s;
    }

    .spin::after {
      border: 0 solid transparent;
    }

    .spin.active::after {
      border-top: 7px solid #70d47e;
      border-left-width: 7px;
      border-right-width: 7px;
      transform: rotate(270deg);
      transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s,
        -webkit-transform 0.4s linear 0s;
    }

    .circle {
      border-radius: 100%;
      box-shadow: none;
    }

    .circle::before,
    .circle::after {
      border-radius: 100%;
    }

    @media (max-width: 1030px) {
      width: calc(100% + 13px);
      padding-top: calc(100% + 13px);
    }
  `
  return (
    <CastContainer className="cast-container">
      <div>
        <h3>
          {castInfo[currentCast].name.split(" ")[0]}{" "}
          <span style={{ color: "#70d47e", fontWeight: "900" }}>
            {castInfo[currentCast].name.split(" ")[1]}
          </span>
        </h3>
        {castInfo[currentCast].description}
      </div>
      <div className="cast-image-container">
        {images.map((image, index) => {
          return (
            <div onClick={() => setCurrentCast(index)} className="small">
              <Img fluid={image.node.childImageSharp.fluid} />
              <CircleContainer>
                <button
                  className={`spin circle ${
                    currentCast === index ? "active" : ""
                  }`}
                ></button>
              </CircleContainer>
            </div>
          )
        })}
        <Img
          className="main"
          fluid={images[currentCast].node.childImageSharp.fluid}
        />
      </div>
      <div className="cast-slider-container">
        <Slider
          initialSlide={currentCast}
          className="cast-slider"
          {...settings}
        >
          {images.map((image, index) => {
            return (
              <div onClick={() => setCurrentCast(index)}>
                <Img
                  className="image-container"
                  fluid={image.node.childImageSharp.fluid}
                />
                <CircleContainer>
                  <button
                    className={`spin circle ${
                      currentCast === index ? "active" : ""
                    }`}
                  ></button>
                </CircleContainer>
              </div>
            )
          })}
        </Slider>
      </div>
    </CastContainer>
  )
}

export default Cast
