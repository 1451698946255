import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from "gatsby-image"
import { Fade } from "react-reveal"

function CharSlider(props) {
  const [revealed, setRevealed] = useState(undefined)

  const photos = useStaticQuery(graphql`
    query Photos {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/images/chars/" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const images = photos.allFile.edges.sort((a, b) => {
    return parseFloat(a.node.name) - parseFloat(b.node.name)
  })
  var settings = {
    slidesToShow: images.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 6,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          arrows: true,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
    ],
  }
  const SliderContainer = styled.div`
    position: relative;
    z-index: 9;
    .slick-prev:before,
    .slick-next:before {
      color: black;
    }
    .image-container {
      position: relative;
      z-index: 2;
    }
    .char-slider {
      max-width: 1400px;
      margin: 0 auto;
    }
    .char-slider img {
      width: 100%;
    }
    .char-slider .slick-track {
      display: flex;
      align-items: flex-end;
    }
    .char-slider .slick-track > * {
      margin: 20px;
      position: relative;
      cursor: pointer;
    }
    .char-slider .slick-track > *:hover .spin::before {
      border-top-color: #70d47e;
      border-right-color: #70d47e;
      border-bottom-color: #70d47e;
      transition: border-top-color 0.15s linear,
        border-right-color 0.15s linear 0.1s,
        border-bottom-color 0.15s linear 0.2s;
    }
    .char-slider .slick-track > *:hover .spin::after {
      border-top: 7px solid #70d47e;
      border-left-width: 7px;
      border-right-width: 7px;
      transform: rotate(270deg);
      transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s,
        -webkit-transform 0.4s linear 0s;
    }
    .char-slider .slick-track * {
      outline: none;
    }
    @media (max-width: 1100px) {
      padding: 0 25px;
    }
    @media (max-width: 767px) {
      .char-slider .slick-track > *:hover .spin::before {
        display: none;
      }
      .char-slider .slick-track > *:hover .spin::after {
        display: none;
      }
    }
  `
  const CircleContainer = styled.div`
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%);
    width: calc(100% + 14px);
    padding-top: calc(100% + 14px);
    button {
      border: 0;
      position: relative;
      background: none;
    }

    button::before,
    button::after {
      box-sizing: inherit;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .spin {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .spin::before,
    .spin::after {
      top: 0;
      left: 0;
    }

    .spin::before {
      border: 7px solid transparent;
    }

    .spin.active::before {
      border-top-color: #70d47e;
      border-right-color: #70d47e;
      border-bottom-color: #70d47e;
      transition: border-top-color 0.15s linear,
        border-right-color 0.15s linear 0.1s,
        border-bottom-color 0.15s linear 0.2s;
    }

    .spin::after {
      border: 0 solid transparent;
    }

    .spin.active::after {
      border-top: 7px solid #70d47e;
      border-left-width: 7px;
      border-right-width: 7px;
      transform: rotate(270deg);
      transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s,
        -webkit-transform 0.4s linear 0s;
    }

    .circle {
      border-radius: 100%;
      box-shadow: none;
    }

    .circle::before,
    .circle::after {
      border-radius: 100%;
    }
  `

  const onEnter = () => {
    setRevealed(true)
  }

  return (
    <SliderContainer>
      <Slider
        initialSlide={props.currentChar}
        className="char-slider"
        {...settings}
      >
        {images.map((image, index) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.setCurrentChar(index)}
            >
              <Img
                className="image-container"
                fluid={image.node.childImageSharp.fluid}
              />
              <CircleContainer>
                <button
                  className={`spin circle ${
                    props.currentChar === index ? "active" : ""
                  }`}
                ></button>
              </CircleContainer>
            </div>
          )
        })}
      </Slider>
    </SliderContainer>
  )
}

export default CharSlider
