import React from "react"

export default [
  {
    name: "Chris Forsythe",
    description: (
      <>
        <p>
          Chris Forsythe has filmmaking degrees from the Zaki Gordon Film
          Institute and the University of Nevada, Las Vegas. Currently, he lives
          in San Diego with his son, working as a production assistant and
          copywriter. Drawing on his own hands-on experience in the retail tech
          space, Chris has developed Cucumber into a compelling story packed
          with quirky and humorous characters in a unique but real-life setting
          that results in hilarious shenanigans.
        </p>
      </>
    ),
  },
  {
    name: "Mike Slater",
    description: (
      <p>
        Mike Slater is a co-creator of Cucumber and a multidisciplinary art
        director with an emphasis in 3D visualization and animation.
      </p>
    ),
  },
  {
    name: "Barrett Applegate",
    description: (
      <p>
        Barrett Applegate is a large-calved man from Las Vegas, NV who flips
        houses by day and does cartoon voiceovers by night.
      </p>
    ),
  },
  {
    name: "Lizzy Delgado",
    description: (
      <p>
        Lizzy Delgado is an aspiring actor, musician, and storyteller from Los
        Angeles.
      </p>
    ),
  },
  {
    name: "Harrison Reynolds",
    description: (
      <p>
        Harrison Reynolds is a writer, recording artist, and (ugh!) an
        entertainment lawyer. But he’s still pretty cool, we promise.
      </p>
    ),
  },
  {
    name: "Michael Eicher",
    description: (
      <p>
        Michael Eicher loves to create through his designs, illustrations, or
        photography. He spends the other half of his days being a full-time dad.
      </p>
    ),
  },
  {
    name: "Danny Shepherd",
    description: (
      <p>
        Danny Shepherd is an actor and filmmaker best known for his production
        team and YouTube Channel, Ismahawk.
      </p>
    ),
  },
]
