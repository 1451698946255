import React, { useEffect, useState, useRef } from "react"
import { Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image"
import topLogo from "../images/top-newest.svg"
import form1 from "../images/form1.svg"
import form2 from "../images/form2.svg"
import form3 from "../images/form3.svg"
import notify from "../images/notify.svg"
import trailer from "../images/trailer.svg"
import signUp from "../images/sign-up.svg"
import logoCopy from "../images/logo copy.svg"
import kickstarter from "../images/kickstarter.svg"
import cucumberShape from "../images/cucumberShape.svg"
import { Fade } from "react-reveal"
import Characters from "../components/Characters"
import trailderVideo from "../videos/Cucumber.mp4"
import MicroModal from "react-micro-modal"
import ig from "../images/share.svg"
import Rodal from "rodal"
import "rodal/lib/rodal.css"
import Cast from "../components/Cast"
import Opener from "../components/Opener"
import $ from "jquery"
import Countdown from "react-countdown"
import Pol from "../components/Pol"
import { useMediaQuery } from "react-responsive"

const Header = styled.header`
  width: 100%;
  color: #2d373a;
  background: #70d47e;
  font-size: 21px;
  text-align: center;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Top = styled.section`
  position: relative;
  height: 56vw;

  > .bg-image-fit img {
    object-position: center top !important;
  }
  @media (max-width: 767px) {
    height: 115vw;
  }
`

const TopLogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  .cucumber-shape {
    position: absolute;
    left: 50%;
    width: 15vw;
    top: -1vw;
  }
  .top-logo-container {
    position: relative;
  }
  .top-logo-container > .top-logo {
    position: relative;
    right: -5vw;
    width: 50vw;
  }

  > .kickstarter-container {
    width: 20vw;
    text-align: center;
    color: #2d373a;
    font-size: 22px;
    margin: -3.5vw auto 0;
    position: relative;
  }
  > .kickstarter-container p {
    font-size: 1.3vw;
    margin: 0.5vw;
  }
  > .cta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 2vw;
  }
  > .cta-container > div {
    width: 17vw;
  }
  > .cta-container button {
    background: none;
    border: none;
    width: 100%;
    outline: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cta-container > div:nth-of-type(1) .cta-content {
    position: absolute;
    width: 100%;
    color: white;
    font-size: 1.5vw;
    top: 1vw;
    left: 0;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cta-container > div:nth-of-type(1) .cta-content span {
    color: #aed843;
    font-size: 1vw;
    margin-left: 0.4vw;
  }
  .cta-container > div:nth-of-type(2) .cta-content {
    position: absolute;
    width: 100%;
    color: #2d373a;
    font-size: 1.5vw;
    top: 1.2vw;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > .cta-container > div:nth-of-type(1) {
    margin-left: 9vw;
    margin-bottom: 1vw;
  }
  > .cta-container > div:nth-of-type(2) {
    margin-right: 9vw;
  }
  > .cta-container img {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 80%;
    .cucumber-shape {
      left: 40%;
      width: 23vw;
    }
    .top-logo-container > .top-logo {
      right: 0;
      width: 100%;
    }
    .kickstarter-container {
      width: 28vw;
      margin: -4.5vw auto 0;
    }
    .kickstarter-container p {
      font-size: 2.5vw;
    }
    > .cta-container > div {
      width: 40vw;
    }
    .cta-container > div:nth-of-type(1) .cta-content,
    .cta-container > div:nth-of-type(2) .cta-content {
      font-size: 3.5vw;
      top: 2.5vw;
    }
    .cta-container > div:nth-of-type(1) .cta-content span {
      font-size: 2vw;
    }
  }
`

const LeftChar = styled.div`
  width: 34vw;
  position: absolute;
  bottom: 6vw;
  left: 0;
  z-index: 1;
  @media (max-width: 767px) {
    width: 40vw;
    bottom: 1vw;
  }
`

const RightChar = styled.div`
  width: 22vw;
  position: absolute;
  bottom: 6vw;
  right: 10vw;
  z-index: 1;
  @media (max-width: 767px) {
    width: 28vw;
    bottom: 1vw;
  }
`

const Section2 = styled.section`
  position: relative;
  background: #70d47e;
  padding-bottom: 3vw;
  @media (max-width: 767px) {
    margin-top: -17vw;
  }
`

const Section2FormContainer = styled.div`
  max-width: 860px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  top: -70px;
  padding: 0 20px;
  margin-top: 75px;
  > div:nth-of-type(1) {
    z-index: 0;
    margin-bottom: -2vw;
  }
  > div:nth-of-type(2) {
    z-index: 2;
  }
  > div {
    position: relative;

    flex-basis: calc(50% - 10px);
  }
  h3 {
    font-size: 35px;
    color: white;
  }
  input {
    font-size: 22px;
    position: absolute;
    left: 20px;
    width: 90%;
    background: none;
    border: none;
    outline: none;
    color: white;
  }
  form > div {
    position: relative;
    display: flex;
    align-items: center;
  }
  form button {
    margin-top: 10px;
    outline: none;
    background: none;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button span {
    font-size: 31px;
    font-weight: bold;
    color: white;
    position: absolute;
  }
  @media (max-width: 767px) {
    justify-content: center;
    padding-top: 32vw;
    max-width: 80vw;
    form > div:last-of-type {
      width: 200px;

      > button {
        width: 100%;
        > span {
          font-size: 23px;
        }
        > img {
          width: 100%;
        }
      }
    }
    h3 {
      font-size: 27px;
    }
    > div:nth-of-type(1) {
      display: none;
    }
    > div {
      flex-basis: 100%;
    }
  }
`

const Section3 = styled.section`
  position: relative;
  background: #2d373a;
  color: white;
  padding: 115px 20px 0;
  margin-top: -70px;
  h2 {
    margin-top: 0;
  }
  @media (max-width: 780px) {
    padding-top: 40px;
  }
`

const GreenImage = styled.div`
  width: 100%;
  height: 15vw;
  position: absolute;
  bottom: 0;
  > .bg-image-fit img {
    object-position: center top !important;
  }
  @media (max-width: 767px) {
    bottom: 17vw;
  }
`

const GreyImage = styled.div`
  width: 100%;
  height: 6vw;
  position: absolute;
  z-index: 1;
  bottom: 70px;
  > .bg-image-fit img {
    object-position: center top !important;
  }
`

const Section3ContentContainer = styled.div`
  max-width: 1800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  > div {
    flex-basis: 50%;
  }
  > div:nth-of-type(1) {
    display: flex;
    justify-content: flex-end;
    margin-right: 35px;
  }
  > div:nth-of-type(2) {
    margin-left: 35px;
  }
  > div:nth-of-type(1) > div {
    max-width: 580px;
  }
  @media (max-width: 780px) {
    flex-direction: column;
    > div:nth-of-type(1) > div {
      max-width: 100%;
    }
    > div:nth-of-type(1) {
      margin-right: 0px;
    }
    > div:nth-of-type(2) {
      margin-left: 0px;
      margin-top: 60px;
    }
  }
`

const PolaroidContainer = styled.div`
  background: white;
  color: #2d373a;
  padding: 2.5vw;
  transform: rotate(5deg);
  position: relative;
  right: 9px;
  h4 {
    font-size: 1.5vw;
    margin: 2.5vw 5vw 0;
    text-align: center;
  }
  h4 span {
    color: #aed843;
    font-size: 2vw;
  }
  @media (max-width: 780px) {
    right: 0px;
  }
`

const Char1 = styled.div`
  > .eyeball-container:nth-of-type(1) {
    position: absolute;
    left: 44.7%;
    top: 10.06%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  > .eyeball-container:nth-of-type(2) {
    position: absolute;
    left: 48.5%;
    top: 10%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const Char2 = styled.div`
  > .eyeball-container:nth-of-type(1) {
    position: absolute;
    left: 70.7%;
    top: 2.5%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2vw;
    height: 1.2vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      top: 0px;
      left: 2px;
      width: 6px;
      height: 6px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  > .eyeball-container:nth-of-type(2) {
    position: absolute;
    left: 75.7%;
    top: 2.3%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.2vw;
    height: 1.2vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      top: 0px;
      left: 2px;
      width: 6px;
      height: 6px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const Char3 = styled.div`
  > .eyeball-container:nth-of-type(1) {
    position: absolute;
    left: 7.4%;
    top: 8.6%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  > .eyeball-container:nth-of-type(2) {
    position: absolute;
    left: 13.8%;
    top: 8.4%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const Char4 = styled.div`
  > .eyeball-container:nth-of-type(1) {
    position: absolute;
    left: 40.6%;
    top: 8.3%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  > .eyeball-container:nth-of-type(2) {
    position: absolute;
    left: 45.5%;
    top: 8.4%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const Char5 = styled.div`
  > .eyeball-container:nth-of-type(1) {
    position: absolute;
    left: 72.5%;
    top: 9.75%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  > .eyeball-container:nth-of-type(2) {
    position: absolute;
    left: 78.5%;
    top: 9.8%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.6vw;
    height: 0.6vw;
    > .eye {
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .eye:after {
      position: absolute;
      left: 3px;
      top: 0px;
      width: 3px;
      height: 3px;
      background: #000;
      border-radius: 50%;
      content: " ";
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const EyeballContainer = styled.div`
  position: absolute;
  left: 220px;
  top: 49px;
  z-index: 1;

  .eye:nth-of-type(2) {
    right: -9px;
  }
  .eye {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    height: 9px;
    width: 9px;
  }
  .eye:after {
    /*pupil*/
    position: absolute;
    left: 3px;
    top: 0px;
    width: 2px;
    height: 2px;
    background: #000;
    border-radius: 50%;
    content: " ";
  }
`

const Section5 = styled.section`
  position: relative;
  padding: 20px;
`

const Section5ContentContainer = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  p {
    color: white;
  }
  p span {
    color: #70d47e;
  }
  > img {
    margin-top: -127px;
    margin-bottom: 45px;
  }
  .cta-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 3vw;
  }
  .cta-container > div {
    width: 17vw;
  }
  .cta-container button {
    background: none;
    border: none;
    width: 100%;
    outline: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -3vw;
  }
  /* .cta-container > div:nth-of-type(1) .cta-content {
    position: absolute;
    width: 100%;
    color: white;
    font-size: 1.5vw;
    top: 1vw;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cta-container > div:nth-of-type(1) .cta-content span {
    color: #aed843;
    font-size: 1vw;
    margin-left: 0.4vw;
  } */
  .cta-container > div:nth-of-type(1) .cta-content {
    position: absolute;
    width: 100%;
    color: #2d373a;
    font-size: 1.5vw;
    top: 1.2vw;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cta-container > div:nth-of-type(2) .cta-content {
    position: absolute;
    width: 100%;
    color: white;
    font-size: 1.5vw;
    top: 1.2vw;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .cta-content span {
    color: #aed843;
    font-size: 1vw;
    margin-left: 0.4vw;
  }

  .cta-container img {
    width: 100%;
  }
  @media (max-width: 780px) {
    > img {
      display: none;
    }
    .cta-container > div {
      width: 40vw;
    }
    .cta-container > div:nth-of-type(1) .cta-content,
    .cta-container > div:nth-of-type(2) .cta-content {
      font-size: 3.5vw;
      top: 2.5vw;
    }
    .cta-content span {
      font-size: 2vw;
    }
  }
  @media (max-width: 767px) {
    .cta-container button {
      margin-bottom: -10vw;
    }
  }
`

const Footer = styled.footer`
  position: relative;
  padding-top: 90px;
  .chars {
    max-width: 850px;
    margin: 0 auto;
  }
  .footer-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    left: 50%;
    padding: 15px 20px;
    box-sizing: border-box;
  }
  .footer-nav-container p {
    margin: 0;
  }
  nav ul {
    display: flex;
  }
  nav ul > li:not(:last-of-type) {
    margin-right: 20px;
  }
  @media (max-width: 940px) {
    .footer-nav-container {
      flex-direction: column;
      background: #f7f6ed;
    }
  }
  @media (max-width: 767px) {
    .footer-nav-container {
      ul,
      p {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 470px) {
    .footer-nav-container ul {
      flex-direction: column;
    }
    .footer-nav-container > div {
      width: 100%;
    }
  }
`

const Section6 = styled.section`
  position: relative;
  padding-top: 145px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  .bg-image-fit img {
  }
  > .cast-container {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    > div {
      position: relative;
    }
    > div:nth-of-type(1) {
      margin-right: 60px;
      flex: 1;
    }
  }
  @media (max-width: 767px) {
    > .cast-container {
      flex-direction: column;
      > div {
        flex-basis: 100%;
      }
      > div:nth-of-type(1) {
        margin-right: 0px;
      }
    }
  }
`

const DescriptionBar = styled.section`
  position: absolute;
  width: 100%;
  bottom: 7vw;
  padding: 1vw 0;
  text-align: center;
  background: #2d373a;
  h1 {
    font-weight: 900;
    font-size: 2vw;
    color: white;
    margin: 0;
    font-weight: 900;
  }
`

const TopLogoText = styled.div`
  width: 53%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  top: 4vw;
  @media (max-width: 767px) {
    left: 40%;
  }
`

const Slogan = styled.div`
  position: absolute;
  top: 5vw;
  right: -3vw;
  font-size: 2vw;
  color: #2d373a;
  font-weight: bold;
  img {
    width: 2vw;
    position: absolute;
    left: 4.5vw;
    top: 2.7vw;
  }
  @media (max-width: 767px) {
    top: 8vw;
    right: 3.3vw;
    font-size: 3vw;
    img {
      width: 4vw;
      position: absolute;
      left: 5.8vw;
      top: 4.7vw;
    }
  }
`

const IndexPage = () => {
  // useEffect(() => {
  //   function moveEye(eye, e) {
  //     const eyeBounding = eye.getBoundingClientRect()
  //     var x = eyeBounding.left + window.scrollX + eyeBounding.width / 2
  //     var y = eyeBounding.top + window.scrollY + eyeBounding.height / 2
  //     var rad = Math.atan2(e.pageX - x, e.pageY - y)
  //     var rot = rad * (180 / Math.PI) * -1 + 180
  //     eye.style["-webkit-transform"] = `rotate(${rot}deg)`
  //     eye.style["-moz-transform"] = `rotate(${rot}deg)`
  //     eye.style["-ms-transform"] = `rotate(${rot}deg)`
  //     eye.style["transform"] = `rotate(${rot}deg)`
  //   }
  //   document
  //     .getElementsByTagName("body")[0]
  //     .addEventListener("mousemove", e => {
  //       var eyes = document.getElementsByClassName("eye")
  //       moveEye(eyes[0], e)
  //       moveEye(eyes[1], e)
  //     })
  // }, [])
  const [viewTrailer, setViewTrailer] = useState(false)
  const [shapeRotate, setShapeRotate] = useState(0)
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      openerBg: file(relativePath: { eq: "openerBg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      topBg: file(relativePath: { eq: "bg-3.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      # kickstarter: file(relativePath: { eq: "kickstarter.png" }) {
      #   childImageSharp {
      #     id
      #     fluid(maxWidth: 340) {
      #       ...GatsbyImageSharpFluid_withWebp_tracedSVG
      #     }
      #   }
      # }
      leftChar: file(relativePath: { eq: "left-char.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      rightChar: file(relativePath: { eq: "right-char.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      leftCharSm: file(relativePath: { eq: "left-char-sm.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      rightCharSm: file(relativePath: { eq: "right-char-sm.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      section2bg: file(relativePath: { eq: "section2-bg.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      news: file(relativePath: { eq: "news.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      section3bg: file(relativePath: { eq: "section3-bg.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      section5bg: file(relativePath: { eq: "section5-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      footerChars: file(relativePath: { eq: "footer-chars.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      footerBg: file(relativePath: { eq: "footer-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      cast: file(relativePath: { eq: "cast.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      section6bg: file(relativePath: { eq: "section6-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      topLogoText: file(relativePath: { eq: "top-logo-text.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const CountdownComp = styled.div`
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate(-50%);
    top: 16vw;

    display: flex;
    align-items: center;
    text-align: center;
    > span:nth-of-type(2) {
      position: relative;
      bottom: -11px;
      margin: 0 20px;
    }
    > span {
      font-size: 2.4vw;
      background: #2d373a;
      border-radius: 100%;
      width: 5vw;
      height: 5vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 900;
      > span {
        font-weight: 300;

        font-size: 1.2vw;
      }
    }

    @media (max-width: 767px) {
      top: 26vw;
      left: 40%;
      > span {
        width: 8vw;
        height: 8vw;
        font-size: 4.4vw;
        > span {
          font-size: 1.5vw;
        }
      }
    }
  `

  const SmContainer = styled.div`
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    top: -3vw;
    img {
      max-width: 30px;
      width: 100%;
    }
    @media (max-width: 767px) {
      top: 27vw;
    }
  `
  const Renderer = ({ days, hours, minutes }) => {
    return (
      <CountdownComp>
        <span>
          {days}
          <br />
          <span>days</span>
        </span>
        <span>
          {hours}
          <br />
          <span>hours</span>
        </span>
        <span>
          {minutes}
          <br />
          <span>min</span>
        </span>
      </CountdownComp>
    )
  }
  const {
    topBg,
    leftChar,
    rightChar,
    leftCharSm,
    rightCharSm,
    section2bg,
    news,
    section3bg,
    section5bg,
    footerChars,
    footerBg,
    cast,
    section6bg,
    topLogoText,
    openerBg,
  } = data
  console.log(data)

  useEffect(() => {
    $("html").on("mousemove", function (event) {
      $(".eye").each((i, eye) => {
        var x = $(eye).offset().left + $(eye).width() / 2
        var y = $(eye).offset().top + $(eye).height() / 2
        var rad = Math.atan2(event.pageX - x, event.pageY - y)
        var rot = rad * (180 / Math.PI) * -1 + 180
        $(eye).css({
          "-webkit-transform": "rotate(" + rot + "deg)",
          "-moz-transform": "rotate(" + rot + "deg)",
          "-ms-transform": "rotate(" + rot + "deg)",
          transform: "rotate(" + rot + "deg)",
        })
      })
    })
  }, [])
  const trailerRef = useRef()
  const handleTrailer = () => {
    trailerRef.current.play()
    setViewTrailer(true)
  }

  const [success, setSuccess] = useState(false)
  const [customer, setCustomer] = useState({})

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = event => {
    event.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...customer,
      }),
    })
      .then(() => setSuccess(true))
      .catch(error => alert(error))
  }

  const handleChange = e => {
    const newCust = { ...customer }
    newCust[e.target.name] = e.target.value
    setCustomer(newCust)
  }
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 767px)",
  })

  return (
    <Layout handleTrailer={handleTrailer}>
      <Opener bg={openerBg} />
      <Rodal
        customStyles={{
          background: "transparent",
          boxShadow: "none",
          width: "80%",
          height: 0,
          paddingTop: "45%",
        }}
        showCloseButton={false}
        visible={viewTrailer}
        onClose={() => {
          trailerRef.current.pause()
          setViewTrailer(false)
        }}
      >
        <video
          ref={trailerRef}
          width={900}
          style={{
            width: "100%",
            position: "absolute",
            height: "100%",
            left: 0,
            top: 0,
          }}
          controls
        >
          <source src={trailderVideo} />
        </video>
      </Rodal>
      <SEO title="Home" />
      <Top>
        <DescriptionBar>
          <h1>10-Part, Heartfelt Comedy TV Series</h1>
        </DescriptionBar>
        <LeftChar>
          <div>
            <Char1>
              <div className="eyeball-container">
                <div className="eye"></div>
              </div>
              <div className="eyeball-container">
                <div className="eye"></div>
              </div>
            </Char1>
            <Char2>
              <div className="eyeball-container">
                <div className="eye"></div>
              </div>
              <div className="eyeball-container">
                <div className="eye"></div>
              </div>
            </Char2>

            <Img
              fluid={
                isTabletOrMobileDevice
                  ? leftCharSm.childImageSharp.fluid
                  : leftChar.childImageSharp.fluid
              }
            />
          </div>
        </LeftChar>
        <RightChar>
          <Char3>
            <div className="eyeball-container">
              <div className="eye"></div>
            </div>
            <div className="eyeball-container">
              <div className="eye"></div>
            </div>
          </Char3>
          <Char4>
            <div className="eyeball-container">
              <div className="eye"></div>
            </div>
            <div className="eyeball-container">
              <div className="eye"></div>
            </div>
          </Char4>
          <Char5>
            <div className="eyeball-container">
              <div className="eye"></div>
            </div>
            <div className="eyeball-container">
              <div className="eye"></div>
            </div>
          </Char5>
          <Img
            fluid={
              isTabletOrMobileDevice
                ? rightCharSm.childImageSharp.fluid
                : rightChar.childImageSharp.fluid
            }
          />
        </RightChar>
        <Img className="bg-image-fit" fluid={topBg.childImageSharp.fluid} />
        <TopLogoContainer>
          <div className="top-logo-container">
            <img className="top-logo" src={topLogo} />
            <Countdown
              date={new Date("Apr 01 2021")}
              renderer={({ seconds }) => {
                return (
                  <img
                    style={{
                      transform: `translate(-50%) rotate(-${seconds / 60}turn)`,
                    }}
                    src={cucumberShape}
                    className="cucumber-shape"
                  />
                )
              }}
            />
            <Slogan>Think Fresh</Slogan>
            <TopLogoText>
              <Img fluid={topLogoText.childImageSharp.fluid} />
            </TopLogoText>
          </div>
          {/* <Countdown date={new Date("Apr 01 2021")} renderer={Renderer} /> */}
          <div className="kickstarter-container">
            <img src={kickstarter} />
            <p>now live!</p>
          </div>
          <div className="cta-container">
            <div>
              {!isTabletOrMobileDevice && (
                <button>
                  <a id="notify-button" target="_blank" href="https://www.kickstarter.com/projects/thecucumbershow/cucumber-the-animated-series?ref=user_menu">
                    <img src={notify} />
                    <span class="cta-content">
                      CHECK IT OUT!
                    </span>
                  </a>
                </button>
              )}
            </div>
            <div>
              <button>
                <a id="trailer-button" onClick={handleTrailer}>
                  <img src={trailer} />
                  <span class="cta-content">VIEW TRAILER</span>
                </a>
              </button>
            </div>
          </div>
        </TopLogoContainer>
        <GreenImage>
          <Img
            className="bg-image-fit"
            fluid={section2bg.childImageSharp.fluid}
          />
        </GreenImage>
      </Top>
      <Section2>
        <SmContainer>
          <a
            href="https://www.instagram.com/cucumbershow84/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/ig.svg" />
          </a>
          <a
            href="https://www.facebook.com/TheCucumberShow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/fb.svg" />
          </a>
          <a
            href="https://twitter.com/thecucumbershow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/twitter.svg" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCfVLkkaQh2iDnSAn12hAj8w"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/youtube.svg" />
          </a>
        </SmContainer>
        <Section2FormContainer>
          <div>
            <Fade bottom duration={300}>
              <div style={{ position: "relative" }}>
                <Img fluid={news.childImageSharp.fluid} />
              </div>
            </Fade>
          </div>
          <div>
            <Fade bottom duration={300}>
              {success ? (
                <p style={{ textAlign: "center", color: "white", height: 150 }}>
                  Thanks for signing up!
                </p>
              ) : (
                <div>
                  <h3 id="notify">Sign Up for Updates!</h3>
                  <form
                    id="form"
                    name="sign-up"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                  >
                    <div>
                      <img style={{ width: "100%" }} src={form1} />
                      <input
                        onChange={handleChange}
                        required
                        type="text"
                        placeholder="Name"
                        name="Name"
                      />
                    </div>
                    <div>
                      <img style={{ width: "100%" }} src={form2} />
                      <input
                        onChange={handleChange}
                        required
                        type="email"
                        placeholder="Email"
                        name="Email"
                      />
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <button type="submit">
                        <img src={form3} />
                        <span>yessss</span>
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Fade>
          </div>
        </Section2FormContainer>
        <GreyImage>
          <Img
            className="bg-image-fit"
            fluid={section3bg.childImageSharp.fluid}
          />
        </GreyImage>
      </Section2>
      <Section3>
        <Section3ContentContainer>
          <div>
            <Fade up duration={400}>
              <div>
                <h2>the story</h2>
                <p>
                  When Carl Cucumber invented the qBook he ignited a
                  tech-revolution. 50 years later, the world is inundated with
                  qPhones and qBots. Hundreds of Cucumber stores span the globe
                  and the company is the most valuable in history.
                </p>
                <p>
                  Managing store #R034 is Aaron, a die-hard fan with dreams of
                  changing the world alongside his childhood hero. He’s joined
                  by a team of geeks, hipsters, outcasts, and bros who serve on
                  the frantic frontlines of Cucumber retail. Good thing there’s
                  a T-REX working security, a wizard making repairs at The
                  Wizard Bar, and the qCafe keeping everyone caffeinated.
                </p>
                <p>
                  Aaron’s drive and passion for Cucumber make him perfect
                  corporate material, but events currently in motion are
                  unfolding rapidly. Carl Cucumber has seen the future. He has
                  seen...The Qpocalypse. Now he’s on a quest to avert disaster,
                  which may threaten the very future of the company. The only
                  person standing in his way is Aaron.
                </p>
                <p>
                  It won’t be easy with an ongoing turf war with the nearby
                  Cybersoft store. Not to mention the corporate spy that’s
                  infiltrated the team. Things truly spiral out of control when
                  a disgruntled AI leads a qBot uprising against humanity. Aaron
                  and Cucumber’s intertwined fates will collide in an epic
                  showdown that will decide the fate of the world.
                </p>
              </div>
            </Fade>
          </div>
          <div>
            <Fade right duration={400}>
              <PolaroidContainer>
                <Pol />

                <h4>
                  Aaron and Cucumber’s intertwined fates will collide in an epic
                  showdown that will decide the <span>fate of the world</span>.
                </h4>
              </PolaroidContainer>
            </Fade>
          </div>
        </Section3ContentContainer>
      </Section3>
      <Characters />
      <Fade up duration={400}>
        <Section5>
          <Img
            className="bg-image-fit"
            fluid={section5bg.childImageSharp.fluid}
          />
          <Section5ContentContainer>
            <img src={logoCopy} />
            <h2 style={{ textAlign: "center", marginTop: 0, color: "white" }}>
              heart of the story
            </h2>
            <p>
              Cucumber Computers has gone through several iterations. The nature
              of the story can go in any direction. As a satirical peak behind
              the scenes of tech retail, the potential is limitless for
              over-the-top nonsense. Our society has become addicted to gadgets
              and social media, a point Cucumber takes every opportunity to make
              fun of, but what mattered more to us was heart. As much as we love
              the wacky, we value compelling stories and characters more. We
              want you to love and care about the fate of these Cucumber
              misfits.
            </p>
            <p>
              To find the emotional foundation, we looked to our own experience
              for inspiration. Trust the universe. It’s something we’ve lived by
              throughout this entire project. When obstacles present themselves,
              we don’t give up, but trust the process. Life tends to always make
              sense looking back, a lesson our main character, Aaron, along with
              every member of the misfits, will embody on their individual
              character journeys.
            </p>
            <p>
              There are many other themes intertwined, like the unbreakable bond
              of friendship, betrayal, and the perils presented by technology.
              These themes are wrapped into an epic story that unfolds over
              thousands of years, and 10 episodes.
            </p>
            <div className="cta-container">
              <div>
                <button>
                  <a onClick={handleTrailer} id="trailer-button">
                    <img src={trailer} />
                    <span class="cta-content">VIEW TRAILER</span>
                  </a>
                </button>
              </div>

              <div>
                <button>
                  <a target="_blank" href="https://www.kickstarter.com/projects/thecucumbershow/cucumber-the-animated-series?ref=user_menu">
                  <img src={signUp} />
                  <span class="cta-content">
                    KICKSTARTER
                    <span>learn more</span>
                  </span>
                  </a>
                  
                </button>
              </div>
            </div>
          </Section5ContentContainer>
        </Section5>
      </Fade>
      <Section6>
        <Img
          className="bg-image-fit"
          fluid={section6bg.childImageSharp.fluid}
        />
        <h2 style={{ textAlign: "center", marginTop: 0 }}>cast & crew</h2>
        <Cast />
      </Section6>
      <Footer>
        <Img className="bg-image-fit" fluid={footerBg.childImageSharp.fluid} />
        <div className="chars">
          <Img fluid={footerChars.childImageSharp.fluid} />
        </div>
        <div>
          <p
            style={{
              textAlign: isTabletOrMobileDevice ? "left" : "center",
              margin: 0,
              padding: "0 20px",
              fontSize: isTabletOrMobileDevice ? "12px" : "initial",
            }}
          >
            All characters and events depicted in this film are entirely
            fictitious. Any similarity to actual events or persons, living or
            dead, is purely coincidental.
          </p>
        </div>
        <div className="footer-nav-container">
          <div>
            <p>
              © Copyright {new Date().getFullYear()} Cucumber, All Rights
              Reserved.
            </p>
          </div>
          <div>
            <nav>
              <ul>
                <li>
                  <a href="">Privacy Policy</a>
                </li>
                <li>
                  <a href="">Terms</a>
                </li>
                <li>
                  <a href="https://adtack.com" target="_blank">
                    Las Vegas Digital Agency
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Footer>
    </Layout>
  )
}

export default IndexPage
