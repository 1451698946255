import React, { useState, useEffect } from "react"
import SetInterval from "set-interval"
import CrossfadeImage from "react-crossfade-image"
import polaroid from "../images/polaroid.jpg"
import polaroid2 from "../images/polaroid2.png"
import polaroid3 from "../images/polaroid3.png"
import polaroid4 from "../images/polaroid4.png"

function Pol() {
  const [currPol, setCurrPol] = useState(0)
  const pols = [polaroid, polaroid2, polaroid3, polaroid4]
  function movePol() {
    setCurrPol(prevPol => {
      let pol = prevPol + 1
      if (pol > pols.length - 1) {
        pol = 0
      }
      return pol
    })
  }
  useEffect(() => {
    SetInterval.start(movePol, 4000, "pol")
  }, [])
  return (
    <CrossfadeImage
      style={{
        "-webkit-transform-style": "preserve-3d",
        "-webkit-backface-visibility": "hidden",
        "-webkit-transform": "translateZ(0)",
        top: 0,
        position: "absolute",
      }}
      timingFunction="none"
      src={pols[currPol]}
    />
  )
}

export default Pol
