import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import CharSlider from "./CharSlider"
import Img from "gatsby-image"
import characterInfo from "../character-info/character-info"
import { Fade } from "react-reveal"
import CharacterInfo from "./CharacterInfo"

function Characters() {
  const data = useStaticQuery(graphql`
    query CharactersQuery {
      bg: file(relativePath: { eq: "section4-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const CharactersContainer = styled.section`
    padding: calc(7vw + 1rem) 20px 127px;
    position: relative;
    h2 {
      text-align: center;
      margin-top: 0;
      color: #2d373a;
      margin-bottom: 35px;
    }
    .bg-image-fit img {
      object-position: top right !important;
      object-fit: contain !important;
    }
    @media (max-width: 767px) {
      padding: calc(7vw + 1rem) 20px;
    }
  `
  const [currentChar, setCurrentChar] = useState(0)
  const [revealed, setRevealed] = useState(false)
  const { bg } = data

  return (
    <CharactersContainer>
      <Img className="bg-image-fit" fluid={bg.childImageSharp.fluid} />

      <h2>the characters</h2>
      <CharSlider currentChar={currentChar} setCurrentChar={setCurrentChar} />

      <CharacterInfo
        currentChar={currentChar}
        setCurrentChar={setCurrentChar}
      />
    </CharactersContainer>
  )
}

export default Characters
