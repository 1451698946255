import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import characterInfo from "../character-info/character-info"
import { Fade } from "react-reveal"

function Characters(props) {
  const data = useStaticQuery(graphql`
    query CharacterInfoQuery {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/images/full-chars/" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxHeight: 650) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const CharacterInfoContainer = styled.div`
    display: flex;
    max-width: 1400px;
    height: 650px;
    margin: 30px auto 0;
    align-items: center;
    > div {
      flex-basis: 50%;
    }
    h3 {
      color: #2d373a;
      font-weight: bold;
    }
    p {
      color: #2d373a;
    }
    > div:nth-of-type(1) {
      display: flex;
      justify-content: center;
      > .react-reveal {
        width: 100%;
        max-height: 650px;
        img {
          object-fit: contain !important;
        }
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
        }
      }
    }
    > div:nth-of-type(2) {
      margin-left: 20px;
    }
    @media (max-width: 767px) {
      height: auto;
      > div:nth-of-type(1) {
        display: none;
      }
      > div {
        flex-basis: 100%;
      }
      > div:nth-of-type(2) {
        margin-left: 0px;
      }
    }
  `
  const images = data.allFile.edges.sort((a, b) => {
    return parseFloat(a.node.name) - parseFloat(b.node.name)
  })
  const { currentChar } = props

  const [appear, setAppear] = useState(true)
  return (
    <CharacterInfoContainer>
      <div>
        <Fade
          appear={appear}
          spy={appear ? currentChar : null}
          onReveal={() => setAppear(false)}
          left
          duration={400}
        >
          <Img fluid={images[currentChar].node.childImageSharp.fluid} />
        </Fade>
      </div>
      <div>
        <Fade
          appear={appear}
          spy={appear ? currentChar : null}
          onReveal={() => setAppear(false)}
          right
          duration={400}
        >
          <h3>{characterInfo[currentChar].name}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: characterInfo[currentChar].content,
            }}
          />
        </Fade>
      </div>
    </CharacterInfoContainer>
  )
}

export default Characters
